<template>
  <div class="home">
    <!-- banner -->
    <div class="banner">
      <div class="banner-wrap">
        <div class="left-bg">
          <div class="img2"></div>
          <div class="img3"></div>
        </div>
        <div class="right-text">
          <h3><span class="orange">CN2直连，优质稳定</span></h3>
          <h2>为您的网站使用者提供一个</h2>
          <h2><span>快速、顺畅</span>的浏览体验</h2>
          <p>
            飞兔的高防CDN、WAF、CC、DDoS防护和SSL可以保护网站所有者及其访问者免受各种类型的在线威胁
          </p>
          <div class="c-btn orange" @click="routerJump('contact')">
            立刻体验
          </div>
        </div>
      </div>
    </div>
    <!-- banner -->
    <div class="home-page-content">
      <!-- product -->
      <div class="product">
        <ul class="blue">
          <li>
            <div class="title">智能优化</div>
            <div class="con">
              <div class="txt">
                用多个CDN有如用一个一般简单，为全球各地的使用者提升网站载入速度还有可靠度
              </div>
              <div class="btn" @click="routerJump('optimization')">
                了解更多>
              </div>
              <img class="img-bg" src="../assets/home_card_ic_1.webp" alt="" />
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <div class="title">智能加速</div>
            <div class="con">
              <div class="txt">
                让人工智能自动的为您提升您网站的效能，让使用者在浏览过程中经验良好，抓住更多难得商机
              </div>
              <div class="btn" @click="routerJump('acceleration')">
                了解更多>
              </div>
              <img class="img-bg" src="../assets/home_card_ic_2.webp" alt="" />
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <div class="title">智能保护</div>
            <div class="con">
              <div class="txt">
                在100%的效能运动速度下，有效地管理以及保护您的网站免于凶猛骇客与有害程式的攻击
              </div>
              <div class="btn" @click="routerJump('protection')">了解更多></div>
              <img class="img-bg" src="../assets/home_card_ic_3.webp" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <!-- product -->
      <!-- features -->
      <div class="features">
        <div class="cdn-function">
          <div class="title">飞兔CDN功能</div>
          <div class="des">
            飞兔CDN的优化和安全服务协同工作，可以大幅减少网站，移动APP和客户端的延迟，高防节点同时防止DDoS/CC攻击，恶意机器人和黑客攻击
          </div>
          <ul>
            <li>
              <img class="img" src="../assets/home-content-img01.jpg" alt="" />
              <div class="c-text">
                <div class="t">
                  <span>CDN内容分发</span>
                </div>
                <p>
                  飞兔的CDN在五大洲拥有三十多个数据中心，其中国内我们拥有三线高防服务器机房，可以缓存您网站中的静态内容，可自动将用户指向最快的节点，优化他们的访问。
                </p>
              </div>
            </li>
            <li>
              <img class="img" src="../assets/home-content-img02.jpg" alt="" />
              <div class="c-text">
                <div class="t">
                  <span>网站保护</span>
                </div>
                <p>
                  高防CDN网站保护可使您的网站访问质量得到保护，可用性大幅提高，在面对DDoS、CC、劫持等常规攻击的时候，无需担心
                </p>
              </div>
            </li>
            <li>
              <img class="img" src="../assets/home-content-img03.jpg" alt="" />
              <div class="c-text">
                <div class="t">
                  <span>Web应用防火墙</span>
                </div>
                <p>
                  提供精确的网站指纹识别技术和高效的处理引擎，结合实时更新的高防防御策略，覆盖OWASP主流的攻击类型，保护您的网站免受黑客攻击
                </p>
              </div>
            </li>
            <li>
              <img class="img" src="../assets/home-content-img04.jpg" alt="" />
              <div class="c-text">
                <div class="t">
                  <span>访问优化</span>
                </div>
                <p>
                  飞兔CDN包含一套Web优化功能，可将您的网页压缩50%以上，优化包括最新的Web标准，结合我们的高防CDN，可大幅优化用户访问速度
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="cdn-feature">
          <div class="title">飞兔 CDN 特性</div>
          <ul>
            <li>
              <div class="t">Anycast</div>
              <div class="d">
                在全球范围内从单一IP地址提供您的所有内容，而且延迟较低。
              </div>
            </li>
            <li>
              <div class="t">失效操作</div>
              <div class="d">只需几分钟即可删除缓存的内容。</div>
            </li>
            <li>
              <div class="t">HTTP/2</div>
              <div class="d">
                除了支持HTTP/1.0和HTTP/1.1之外，还支持更高效的HTTP/2协议
              </div>
            </li>
            <li>
              <div class="t">日志记录</div>
              <div class="d">
                与Stackdriver
                Logging相集合，可为您提供关于每次缓存命中和未命中的详情消息。
              </div>
            </li>
            <li>
              <div class="t">HTTPS</div>
              <div class="d">
                提供您自己的SSL/TLS证书，以使用您选择的域名来确保您的内容安全。
              </div>
            </li>
            <li>
              <div class="t">内容源</div>
              <div class="d">
                分发源自Compute Engine虚拟机和Cloud
                Storage存储分区的内容。您甚至可以将多个内容源混合搭配到一个网域中。不支持外部来源服务器。
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- features -->
      <!-- contact -->
      <div class="home-contact">
        <div>
          <ul>
            <li>
              <a target="_blank" href="https://t.me/feeto88888">
                <div class="ico-box">
                  <img
                    class="ico1"
                    src="../assets/home_address_ic_telegram_normal@2x.webp"
                    alt=""
                  />
                  <img
                    class="ico2"
                    src="../assets/home_address_ic_telegram_hover@2x.webp"
                    alt=""
                  />
                </div>
                <div class="text">
                  <p>Telegram</p>
                  <p>@feeto88888</p>
                </div>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=4367844036460"
              >
                <div class="ico-box">
                  <img
                    class="ico1"
                    src="../assets/home_address_ic_whatsapp_normal@2x.webp"
                    alt=""
                  />
                  <img
                    class="ico2"
                    src="../assets/home_address_ic_whatsapp_hover@2x.webp"
                    alt=""
                  />
                </div>
                <div class="text">
                  <p>Whatsapp</p>
                  <p>+4367844036460</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_blank" href="skype:live:.cid.47a5f611eefc376b?chat">
                <div class="ico-box">
                  <img
                    class="ico1"
                    src="../assets/home_address_ic_skype_normal@2x.webp"
                    alt=""
                  />
                  <img
                    class="ico2"
                    src="../assets/home_address_ic_skype_hover@2x.webp"
                    alt=""
                  />
                </div>
                <div class="text">
                  <p>Skype</p>
                  <p>+4367844036460</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_blank" href="mailto:feeto888888@gmail.com">
                <div class="ico-box">
                  <img
                    class="ico1"
                    src="../assets/home_address_ic_email_normal@2x.webp"
                    alt=""
                  />
                  <img
                    class="ico2"
                    src="../assets/home_address_ic_email_hover@2x.webp"
                    alt=""
                  />
                </div>
                <div class="text">
                  <p>Email</p>
                  <p>feeto888888@gmail.com</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- contact -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
};
</script>

<style lang="less" scoped>
@-webkit-keyframes ani-1-plane-7cca3c62 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  25% {
    opacity: 0.8;
    transform: translateY(20px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  75% {
    opacity: 0.8;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ani-1-plane-7cca3c62 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  25% {
    opacity: 0.8;
    transform: translateY(20px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  75% {
    opacity: 0.8;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes ani-1-line-7cca3c62 {
  0% {
    opacity: 0;
    transform: rotate(-100deg);
  }

  10% {
    opacity: 1;
    transform: rotate(-90deg);
  }

  90% {
    opacity: 1;
    transform: rotate(30deg);
  }

  to {
    opacity: 0;
    transform: rotate(40deg);
  }
}

@keyframes ani-1-line-7cca3c62 {
  0% {
    opacity: 0;
    transform: rotate(-100deg);
  }

  10% {
    opacity: 1;
    transform: rotate(-90deg);
  }

  90% {
    opacity: 1;
    transform: rotate(30deg);
  }

  to {
    opacity: 0;
    transform: rotate(40deg);
  }
}

@-webkit-keyframes ani-1-from-right-7cca3c62 {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ani-1-from-right-7cca3c62 {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes ani-1-from-left-7cca3c62 {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ani-1-from-left-7cca3c62 {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.home {
  .banner {
    height: 680px;
    background: #d3e3ff;
    .banner-wrap {
      position: relative;
      height: 680px;
      overflow: hidden;
      background: url(../assets/grid@2x.webp) no-repeat top center;
      background-size: 150% auto;
      .left-bg {
        width: 278px;
        height: 220px;
        margin: 70px auto 30px auto;
        left: 10px;
        position: relative;
        animation: ani-1-from-left-7cca3c62 1s ease-out 0s 1 forwards;
        background: url(../assets/circle-bg@2x.webp) no-repeat 100%/100%;

        .img3 {
          position: absolute;
          width: 68px;
          height: 52px;
          left: 130px;
          top: 20px;
          background-image: url(../assets/plane@2x.webp);
          background-repeat: no-repeat;
          background-size: contain;
          -webkit-animation: ani-1-from-right-7cca3c62 1s ease-out 1 forwards,
            ani-1-plane-7cca3c62 5s linear 0s infinite forwards;
          animation: ani-1-from-right-7cca3c62 1s ease-out 1 forwards,
            ani-1-plane-7cca3c62 5s linear 0s infinite forwards;
        }
        .img2 {
          width: 74px;
          height: 106px;
          left: 80px;
          top: 65px;
          position: absolute;
          background-image: url(../assets/line@2x.webp);
          background-repeat: no-repeat;
          background-size: contain;
          transform-origin: 72px 3px;
          -webkit-animation: ani-1-line-7cca3c62 10s linear 0s infinite forwards;
          animation: ani-1-line-7cca3c62 10s linear 0s infinite forwards;
        }
      }
      .right-text {
        text-align: right;
        padding: 0 20px;
        animation: ani-1-from-right-7cca3c62 1s ease-out 0s 1 forwards;
        h3,
        h2 {
          font-size: 20px;
          color: #000;
          span {
            font-size: 28px;
            color: #335cf4;
            margin: 0 7px 0 0;
            background: linear-gradient(90deg, #3097ff, #005aff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            top: 2px;
            font-weight: bold;
            &.orange {
              font-size: 18px;
              background: linear-gradient(
                90deg,
                rgb(255, 160, 62) 100%,
                rgb(255, 140, 86) 0%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        h3 {
          margin-bottom: 5px;
        }
        p {
          margin-top: 12px;
          text-align: right;
          color: #3a4a6e;
          font-weight: 400;
          margin-bottom: 55px;
          font-size: 14px;
        }
      }
    }
  }
  .home-page-content {
    margin-top: -84px;
  }
  .product {
    ul {
      margin: 30px 16px;
      &.blue {
        .title {
          color: #fff;
          background: linear-gradient(270deg, #00aeff, #008aff);
        }
        .con {
          .btn {
            color: #ff905d;
          }
        }
      }
      li {
        transition: all 0.3s ease;
        box-shadow: 0 10px 25px 0 rgba(0, 60, 179, 0.08);
        &:hover {
          .title {
            color: #fff;
            background: linear-gradient(270deg, #00aeff, #008aff);
          }
          .con {
            background: #fff;
            transition: all 0.3s ease;
          }
        }
        .title {
          height: 54px;
          line-height: 54px;
          padding: 0 22px;
          font-size: 22px;
          background: #bdd4ff;
          transition: all 0.3s ease;
        }
        .con {
          padding: 35px 22px 0;
          position: relative;
          background: #fff;
          transition: all 0.3s ease;
          .txt {
            font-weight: 500;
            color: #3a4a6e;
          }
          .btn {
            color: #999;
            padding-top: 35px;
            height: 48px;
            font-size: 16px;
            padding-bottom: 25px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
              color: #ff905d;
            }
          }
          .img-bg {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100px;
          }
        }
      }
    }
  }
  .features {
    padding: 15px;
    .cdn-function {
      padding-top: 50px;
      .title {
        color: #ff905d;
        font-size: 30px;
        font-weight: bold;
      }
      .des {
        line-height: 24px;
        padding-top: 32px;
      }
      ul {
        li {
          margin-top: 60px;
          overflow: hidden;
          padding-bottom: 40px;
          border-bottom: 1px solid #e4e9f0;
          .c-text {
            width: 510px;
            padding-top: 30px;
            .t {
              font-size: 22px;
              font-weight: 500;
              color: #001734;
            }
            p {
              font-weight: 400;
              color: #3a4a6e;
              margin-top: 20px;
            }
          }
          .img {
            width: 244px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    .cdn-feature {
      padding-top: 60px;
      .title {
        color: #303133;
        font-size: 22px;
        font-weight: bold;
      }
      ul {
        padding-top: 20px;
        overflow: hidden;
        li {
          margin: 15px 0 0 0;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .t {
            line-height: 40px;
            font-size: 18px;
            padding-top: 10px;
            width: 100%;
            background: url(../assets/home_characteristic_pic_bg@2x.webp)
              no-repeat;
            background-size: 100% 100%;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #001734;
            display: flex;
            flex-direction: row;
            &::before {
              content: "";
              display: block;
              width: 40px;
              height: 40px;
              margin-left: 8px;
              margin-right: 8px;
              background-image: url(../assets/home_characteristic_title_ic_setting@2x.webp);
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
          .d {
            min-height: 60px;
            background: #f3f7ff;
            padding: 0 72px 20px 56px;
          }
        }
      }
    }
  }
  .home-contact {
    margin-top: 50px;
    background: #00355c;
    padding: 15px;
    ul {
      overflow: hidden;
      li {
        width: 50%;
        height: 60px;
        float: left;
        overflow: hidden;
        &:hover {
          & > .ico-box > img {
            &:last-child {
              visibility: visible;
            }
          }
          & > .text {
            color: #ff905d;
            & > p {
              opacity: 1 !important;
            }
          }
        }
        .ico-box {
          float: left;
          width: 35px;
          height: 35px;
          position: relative;
          img {
            width: 26px;
            position: absolute;
            left: 0;
            top: 5px;
            transition: all 0.3s ease;
            &:last-child {
              visibility: hidden;
            }
          }
        }
        .text {
          margin-left: 35px;
          color: #fff;
          font-weight: 400;
          p {
            transition: all 0.3s ease;
            &:first-child {
              opacity: 0.7;
            }
            &:last-child {
              opacity: 0.3;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
