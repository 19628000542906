<template>
  <div class="footer-wrap">
    <!-- contact -->
    <div class="footer-contact">
      <div class="contact">
        <h3>联系客服 <br />即可免费体验飞兔智能优化防护</h3>
        <p>告别传统防御方式<br />让您的业务更快、更安全、更省心</p>
        <div class="btns">
          <div class="c-btn" @click="routerJump('contact')">即刻体验</div>
          <div class="c-btn orange" @click="routerJump('price')">查看价格</div>
        </div>
      </div>
    </div>
    <!-- contact -->
    <!-- footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo" @click="routerJump()"></div>
        <div class="right">
          <van-collapse v-model="activeNames">
            <van-collapse-item title="产品与服务" name="1">
              <ul>
                <li @click="routerJump('optimization')">智能优化</li>
                <li @click="routerJump('acceleration')">智能加速</li>
                <li @click="routerJump('protection')">智能保护</li>
              </ul>
            </van-collapse-item>
            <van-collapse-item title="解决方案" name="2">
              <ul>
                <li @click="routerJump('contact')">网站加速</li>
                <li @click="routerJump('contact')">域名加速</li>
                <li @click="routerJump('contact')">DDos防御</li>
              </ul>
            </van-collapse-item>
            <van-collapse-item title="公司相关" name="3">
              <ul>
                <li>关于我们</li>
                <li @click="routerJump('contact')">联系我们</li>
                <li>常见问题</li>
                <li>帮助中心</li>
              </ul>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <div class="copyright">Copyright ©2020 Feeto. All rights reserved</div>
  </div>
</template>

<script>
export default {
  name: "NavPage",
  props: {
    msg: String,
  },
  data() {
    return {
      activeNames: ["1"],
    };
  },
  methods: {
    changeLanguage() {},
  },
};
</script>

<style scoped lang="less">
.footer-wrap {
  .footer-contact {
    text-align: center;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff) 0 100%
        repeat-x,
      url(../assets/home_contact_pic_line1@2x.webp) 0 0 no-repeat,
      url(../assets/home_contact_pic_line2@2x.webp) 100% 100% no-repeat,
      linear-gradient(180deg, rgba(0, 111, 255, 0.4), rgba(243, 247, 255, 0.4))
        0 0 repeat-x;
    background-size: 100% 34px, 160px 120px, 300px 80px, 100% 100%;
    .contact {
      padding-bottom: 40px;
      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #001734;
        padding-top: 50px;
      }
      p {
        margin-top: 25px;
        font-weight: 400;
        color: #3a4a6e;
      }
      .btns {
        margin: 44px auto 0;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        .c-btn {
          flex: 1;
          margin-right: 20px;
        }
      }
    }
  }
  .footer {
    padding: 40px 0;
    border-top: 1px solid #e4e9f0;
    .logo {
      width: 200px;
      height: 50px;
      margin-left: 15px;
      margin-bottom: 30px;
      background: url(@/assets/logo-blue.jpg) no-repeat left top;
      background-size: auto 100%;
    }
    .right {
      ::v-deep .van-cell__title span {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #001734;
      }
      ul {
        li {
          line-height: 34px;
          font-size: 14px;
          cursor: pointer;
          color: #3a4a6e;
          padding-left: 15px;
          &:hover {
            color: #ff905d;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
    font-weight: 500;
    color: #3a4a6e;
  }
}
</style>
