<template>
  <div class="nav-bg">
    <div class="nav-wrap">
      <div class="logo-wrap fl" @click="routerJump()">
        <div class="logo"></div>
        <div class="slogan">
          <h1>飞兔极速云</h1>
          <p>专注CDN研发10余年</p>
        </div>
      </div>
      <div class="menu" @click="clickMenu"></div>
      <!-- <div class="language fr" @click="changeLanguage()">
        {{ language }}
      </div> -->
    </div>
    <van-popup
      v-model="isMenuShow"
      position="right"
      class="home-menu"
      :style="{ height: '100%', width: '70%' }"
    >
      <div class="menu-con">
        <!-- <div class="logo" @click="routerJump()"></div> -->
        <van-nav-bar
          :right-text="language"
          left-arrow
          @click-left="clickMenu"
          @click-right="changeLanguage"
        />
        <ul class="menu-list">
          <li>
            <div class="t" @click="clickMenuList()">{{ $t("h-home") }}</div>
          </li>
          <li>
            <van-collapse v-model="activeNames">
              <van-collapse-item title="产品" name="1">
                <ul>
                  <li @click="clickMenuList('optimization')">智能优化</li>
                  <li @click="clickMenuList('acceleration')">智能加速</li>
                  <li @click="clickMenuList('protection')">智能保护</li>
                </ul>
              </van-collapse-item>
            </van-collapse>
          </li>
          <li>
            <div class="t" @click="clickMenuList('price')">价格</div>
          </li>
          <li>
            <div class="t" @click="clickMenuList('contact')">联系方式</div>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "NavPage",
  props: {
    msg: String,
  },
  data() {
    return {
      language: "English",
      isMenuShow: false,
      activeNames: [""],
    };
  },
  methods: {
    clickMenu() {
      this.isMenuShow = !this.isMenuShow;
    },
    clickMenuList(path) {
      this.clickMenu();
      this.routerJump(path);
    },
    changeLanguage() {
      if (this.$i18n.locale == "zh-cn") {
        this.$i18n.locale = "en";
        this.language = "中文";
      } else {
        this.$i18n.locale = "zh-cn";
        this.language = "English";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav-bg {
  width: 100%;
  height: 50px;
  background: #0057da;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  font-weight: 400;
  .nav-wrap {
    color: #fff;
    .logo-wrap {
      overflow: hidden;
    }
    .logo {
      width: 34px;
      color: #fff;
      cursor: pointer;
      height: 34px;
      float: left;
      margin: 8px;
      background: url(@/assets/logo.jpg) no-repeat left center;
      background-size: auto 100%;
    }
    .slogan {
      float: left;
      margin-left: 10px;
      line-height: 1.5;
      opacity: 0.9;
      margin: 8px 0 0 0;
      h1 {
        font-size: 12px;
        font-weight: bold;
      }
      p {
        font-size: 10px;
        opacity: 0.8;
      }
    }
    .menu {
      float: right;
      width: 44px;
      height: 44px;
      margin: 3px 3px 0 0;
      background: url(@/assets/com_nav_ic_menu_normal@2x.webp) no-repeat center
        center;
      background-size: 100%;
    }
    .language {
      cursor: pointer;
      &:hover {
        color: #ff905d;
      }
    }
  }
}
::v-deep {
  .home-menu {
    padding-top: 10px;
    background: linear-gradient(137deg, #036bfb, #0130b2), rgba(0, 32, 121, 0.7);
    .menu-con {
      .van-nav-bar {
        background: none;
        &::after {
          border: none;
        }
        .van-icon,
        .van-nav-bar__text {
          color: #fff;
        }
        .van-icon {
          font-size: 22px;
        }
      }
      .menu-list {
        padding-top: 10px;
        li {
          line-height: 54px;
          font-size: 14px;
          padding: 0 20px;
          color: #fff;
          border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
          .t {
            height: 54px;
          }
          .van-collapse {
            &::after {
              border: none;
            }
            li {
              border: none;
              padding: 0;
              line-height: 40px;
              opacity: 0.8;
            }
            .van-cell__right-icon,
            .van-cell {
              padding-left: 0;
              padding-right: 0;
              background: none;
              color: #fff;
              &:after {
                border: none;
              }
            }
            .van-collapse-item__content {
              background: none;
            }
          }
        }
      }
    }
  }
}
</style>
